import { HttpClient } from "../HttpClient";
import { isVVV } from "@/helpers/siteIdentifier";

/**
 * Get the current user details from the API.
 * @param {string | number} userId - Optional user ID for the current user request.
 * @returns {Promise<Object>} - A promise that resolves to the user data or rejects with an error.
 */
export const GetCurrent = async (userId) => {
  const assignedDashboard = isVVV() ? "3" : "2";

  const url = userId
    ? `api/users/Current?userId=${userId}&assignedDashboard=${assignedDashboard}`
    : `api/users/Current?assignedDashboard=${assignedDashboard}`;

  try {
    const { data } = await HttpClient.get(url);
    return data;
  } catch (error) {
    console.error("Failed to fetch current user data:", error);
    throw error;
  }
};
